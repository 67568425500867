import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import constants from '../../util/constants';

export const LoginFooterLinks = () => {
  const { t } = useTranslation();
  const platform = Capacitor.getPlatform();

  const platformLabel =
    platform === 'ios'
      ? '(iOS)'
      : platform === 'android'
      ? '(Android)'
      : '(web)';

  return (
    <div style={{ fontSize: '12px', textAlign: 'center' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IonRouterLink
          color="dark"
          href={`${constants.WEB_URL}/support/`}
          target="blank"
        >
          {t('pages.login.Help')}
        </IonRouterLink>{' '}
        &nbsp; | &nbsp;
        <IonRouterLink
          color="dark"
          href={`${constants.WEB_URL}/privacy/`}
          target="blank"
        >
          {t('common.PRIVACY_POLICY')}
        </IonRouterLink>{' '}
        &nbsp; | &nbsp;
        <IonRouterLink
          color="dark"
          href={`${constants.WEB_URL}/terms-of-service/`}
          target="blank"
        >
          {t('pages.login.Terms')}
        </IonRouterLink>
      </div>

      <div
        style={{
          color: '#999',
          paddingTop: '4px',
        }}
      >
        v{process.env.REACT_APP_VERSION} {platformLabel}
      </div>
      <br />
    </div>
  );
};
